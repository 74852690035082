import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { MenuItem, IFooterMenu } from 'interfaces/Common/Menu';
import { footerMenuLinksDefault } from 'helpers/menuLinks';

import ScrollIntoView from 'react-scroll-into-view';
import Link from 'next/link';

import styles from './Footer.module.scss';
import { useSelector } from 'react-redux';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { IStoreLink } from 'interfaces/Common/SocialLinks';
import { storeLinks } from 'helpers/socialLinks';
import TechSupport from '../TechSupport';

const StoreLink: React.FC<IStoreLink> = props => {
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return (
    <div className={styles.footer__storesButtonItem}>
      <Link href={props.link}>
        <a target='_blank'>
          <img
            src={`/images/markets/${currentLanguage}/${props.imageNameBlack}`}
            alt={props.alt}
            className='img'
          />
        </a>
      </Link>
    </div>
  );
};

const Footer: React.FC = () => {
  const { t } = useTranslation('footer');
  const router = useRouter();
  const date = new Date();
  const { rightMenu, centerMenu }: IFooterMenu = footerMenuLinksDefault;

  const isHomePage = useMemo(() => router.pathname === '/', [router.pathname]);
  const currentYear = useMemo(() => date.getFullYear(), [date]);

  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;

  const isRussianGeo = geoDataStored === 'RU';

  const menuItem = (menuItem: MenuItem) => (
    <>
      {menuItem.link.includes('/') ? (
        <Link href={menuItem.link}>
          <a className={cn(styles.footer__menuLink, 'link')}>
            {t(`footer.menu.${menuItem.id}`)}
          </a>
        </Link>
      ) : (
        <>
          {isHomePage ? (
            <ScrollIntoView selector={menuItem.link}>
              <Link href='/'>
                <a
                  onClick={event => {
                    event.preventDefault();
                  }}
                  className={cn(styles.footer__menuLink, 'link')}
                >
                  {t(`footer.menu.${menuItem.id}`)}
                </a>
              </Link>
            </ScrollIntoView>
          ) : (
            <Link href={`/${menuItem.link}`}>
              <a className={cn(styles.footer__menuLink, 'link')}>
                {t(`footer.menu.${menuItem.id}`)}
              </a>
            </Link>
          )}
        </>
      )}
    </>
  );

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footer__body}>
          <div className={styles.footer__list}>
            <div className={cn(styles.footer__item)}>
              <Link href='/'>
                <img
                  src='/images/footer-logo.svg'
                  alt='idChess'
                  className={styles.footer__logo}
                />
              </Link>
              <div className={styles.footer__contacts}>
                <p className={styles.footer__supportText}>
                  {t(`footer.contactUs`)}
                </p>
                <TechSupport />
                <p className={styles.footer__supportText}>
                  {t(`footer.invitation`)}
                </p>
                <div className={styles.footer__social}>
                  <a
                    href='https://t.me/idchess'
                    className={cn(styles.footer__socialIcon, styles.telegram)}
                    target='_blank'
                    rel='noreferrer'
                  />
                  <a
                    href='https://vk.com/idchesscom'
                    className={cn(styles.footer__socialIcon, styles.vk)}
                    target='_blank'
                    rel='noreferrer'
                  />
                  {!isRussianGeo && (
                    <a
                      href='https://www.instagram.com/idchess_/'
                      className={cn(styles.footer__socialIcon, styles.inst)}
                      target='_blank'
                      rel='noreferrer'
                    />
                  )}
                  <a
                    href='https://www.youtube.com/channel/UCvVUGKB98M0lgv1_q9l5fXg'
                    className={cn(styles.footer__socialIcon, styles.youtube)}
                    target='_blank'
                    rel='noreferrer'
                  />
                  {!isRussianGeo && (
                    <a
                      href='https://www.facebook.com/idchess'
                      className={cn(styles.footer__socialIcon, styles.facebook)}
                      target='_blank'
                      rel='noreferrer'
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.footer__item}>
              <nav className={styles.footer__menu}>
                <ul className={styles.footer__menuList}>
                  {rightMenu.map((menuLink: MenuItem, index) => (
                    <li key={index} className={styles.footer__menuItem}>
                      {menuItem(menuLink)}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div
              className={cn(styles.footer__item, styles.footer__itemReverse)}
            >
              <nav className={styles.footer__menu}>
                <ul className={styles.footer__menuList}>
                  {centerMenu.map((menuLink: MenuItem, index) => (
                    <li key={index} className={styles.footer__menuItem}>
                      {menuItem(menuLink)}
                    </li>
                  ))}
                </ul>
              </nav>
              <div className={styles.footer__stores}>
                <StoreLink {...storeLinks.googlePlay} />
                <StoreLink {...storeLinks.appStore} />
                <StoreLink {...storeLinks.appGallery} />
                {isRussianGeo && <StoreLink {...storeLinks.ruStore} />}
              </div>
            </div>
          </div>
          <div className={styles.footer__list}>
            <div className={styles.footer__item}>
              <p className={styles.footer__copyright}>
                {t('footer.copyright')} {currentYear}
              </p>
            </div>
            <div className={cn(styles.footer__item, styles.top)}>
              <div className={styles.footer__privacyLink}>
                <a href='/privacy' className='link' target='_blank'>
                  {t('footer.privacy')}
                </a>
              </div>
              <div className={styles.footer__privacyLink}>
                <a href='/terms' className='link' target='_blank'>
                  {t('footer.userAgreement')}
                </a>
              </div>
            </div>
            <div
              className={cn(styles.footer__item, styles.footer__creatorLogo)}
            >
              <Link href='https://friflex.com/'>
                <a target='_blank'>
                  <img
                    src='/images/footer-friflex-logo.svg'
                    alt=''
                    className={styles.footer__idchessLogo}
                  />
                </a>
              </Link>
              <p className={styles.footer__developedText}>
                {t('footer.developedText')}
                <Link href='https://friflex.com/'>
                  <a target='_blank'>{t('footer.developedLink')}</a>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
