import React from 'react';

const MailIcon: React.FC = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.66663 3.33334H18.3333V16.6667H1.66663V3.33334ZM4.92874 5.00001L9.99996 8.94429L15.0712 5.00001H4.92874ZM16.6666 5.87054L9.99996 11.0557L3.33329 5.87054V15H16.6666V5.87054Z'
      fill='white'
    />
  </svg>
);

export default MailIcon;
