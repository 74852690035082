import React from 'react';
import styles from './TechSupport.module.scss';
import { socialLinks } from 'helpers/socialLinks';
import Link from 'next/link';
import TelegramIcon from '../Icons/Social/TelegramIcon';
import WhatsAppIcon from '../Icons/Social/WhatsAppIcon';
import MailIcon from '../Icons/MailIcon';
import cn from 'classnames';

const TechSupport: React.FC = () => {
  return (
    <>
      <div className={styles.app__contacts}>
        <div className={styles.app__contact}>
          <Link href={socialLinks.telegram}>
            <a
              className={cn(styles.app__contactLinkIcon, styles.telegram)}
              target='_blank'
            >
              <TelegramIcon />
            </a>
          </Link>
        </div>
        <div className={styles.app__contact}>
          <Link href={socialLinks.whatsapp}>
            <a
              className={cn(styles.app__contactLinkIcon, styles.whatsapp)}
              target='_blank'
            >
              <WhatsAppIcon />
            </a>
          </Link>
        </div>
        <div className={styles.app__contact}>
          <Link href={socialLinks.mail}>
            <a
              className={cn(styles.app__contactLinkIcon, styles.mail)}
              target='_blank'
            >
              <MailIcon />
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TechSupport;
